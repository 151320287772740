<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Monitor from "@/services/Monitor";
import ModalAddMonitoredUrl from "@/components/modals/monitor/modalAddMonitoredUrl";
import Swal from "sweetalert2";

export default {
  components: {
    Layout,
    PageHeader,
    ModalAddMonitoredUrl,
  },
  page: {
    title: "URL",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      error: null,
      tableData: [],
      title: "Monitored URLs",
      items: [
        {
          text: "Monitor",
        },
        {
          text: "Monitored URLs",
          active: true,
        },
      ],
      filterInput: {},
      isBusy: false,
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "valid_from",
      sortDesc: true,
      tenantSslData: [],
      fields: [
        {
          key: "url",
          label: "URL",
          visible: true,
        },
        {
          key: "description",
          label: "Description",
          visible: true,
        },
        {
          key: "send_alert",
          label: "Send Alert",
          visible: true,
        },
        {
              key: "timestamp",
              label: "Timestamp",
              visible: true,
        },
        {
          key: "action",
          label: "Action",
        }
      ],
      filterData: false,
      verifyInput: "",
      loading: false,
    };
  },

  async created() {
    await this.getMonitoredUrl();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    async getMonitoredUrl() {
      try {
        this.filterData = true;
        this.isBusy = true;
        const response = await Monitor.getMonitoredUrl();
        this.tableData = response.data.data;
        this.totalRows = this.tableData.length;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
        this.error = error.response?.data?.error || "An error occurred while fetching data.";
        this.tableData = [];
        this.totalRows = 0;
      }
    },

    callModalAddMonitoredUrl() {
      this.$bvModal.show("add_monitored_url");
    },

    removeMonitoredURL(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Remove URL!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          Monitor.deleteMonitoredUrl(id)
              .then((response) => {
                const res = response.data.data || false;
                const error = response.data.error || '';
                if (!res && !error) {
                  this.getMonitoredUrl();
                  this.successmsg("URL Successfully Removed");
                } else {
                  this.failedmsg("URL Removal Failed");
                }
              })
              .catch((error) => {
                this.failedmsg('Fail!', error);
              });
        }
      });
    },


    getExpirationClass(expirationDate) {
      const currentDate = new Date();
      const expiryDate = new Date(expirationDate);

      const timeDiff = expiryDate - currentDate;
      const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));


      if (daysDiff < 7) {
        return 'badge bg-danger font-size-14';
      } else if (daysDiff < 30) {
        return 'badge bg-warning font-size-14';
      } else {
        return 'badge bg-success font-size-14';
      }
    }

  },
  middleware: "authentication",
};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :fields="fields"/>
    <div class="row">
      <div class="col-12">
        <div class="card" v-show="filterData">
          <div class="card-body">
            <a href="javascript:void(0);" class="btn btn-success waves-effect waves-light"
               @click="callModalAddMonitoredUrl">
              <i class="mdi mdi-plus me-2"></i>Add URL
            </a>

            <div class="row mt-4">
              <div class="col d-inline-flex align-items-center">
                <h4 class="card-title m-0">Total Monitored Links: {{ totalRows }}</h4>
              </div>
            </div>
            <div class="row mt-4" v-show="filterData">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="deals-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                        v-model="filter"
                        type="search"
                        placeholder="Search..."
                        class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>

            <!-- Table -->
            <div class="table-responsive mb-0 font-size-12" v-show="filterData">
              <b-table
                  :items="tableData"
                  :busy="isBusy"
                  :fields="fields"
                  :visible="fields.visible"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  show-empty
                  empty-text="No Data Found"
              >

                <template v-slot:cell(send_alert)="data">
                   <span v-if="data.item.send_alert == 1 ">
                    <i class="fa fa-check"></i>
                   </span>
                </template>

                <template v-slot:cell(action)="data">
                  <ul class="list-inline mb-0">
                    <li class="list-inline-item">
                      <a href="javascript:void(0);" class="px-2 text-danger" title="Remove URL" @click="removeMonitoredURL(data.item.id)">
                        <i class="uil uil-trash-alt font-size-18"></i>
                      </a>
                    </li>
                  </ul>
                </template>
                <template v-slot:cell(ssl_expiration)="data">
                    <span v-if="!data.item.isLoading" :class="getExpirationClass(data.item.ssl_expiration)">
                      {{ formatDate(data.item.ssl_expiration) }}
                    </span>
                                    <span v-else>
                      <b-spinner small></b-spinner>
                    </span>
                  <a href="javascript:void(0);" class="ms-2 text-primary" title="Update SSL" @click="updateSSL(data.item)">
                    <i class="uil uil-refresh font-size-18"></i>
                  </a>
                </template>




                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

              </b-table>
            </div>
            <!--Table end -->

            <div class="row" v-show="filterData">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- MODALS -->
    <ModalAddMonitoredUrl @onRefresh="getMonitoredUrl()"></ModalAddMonitoredUrl>
    <!-- /MODALS -->
  </Layout>
</template>

